import React from "react"
import PrivacyUk from "../../../components/body/pages/en-gb/legal/privacy"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const PrivacyUkPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/legal/uk-privacy"}
      title="Privacy Policy | Kuda | The Money App for Africans"
    />
    <PrivacyUk />
  </Layout>
)

export default PrivacyUkPage
